import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { BAC_PAGE_URL } from "@Components/common/site/constants";
import { Link } from "@StarberryUtils";

const RequestAViwing = (props) => {

    const handleClick = () => {
        props?.handleShow()
    }

    return (
        <Container className="footer-btn d-md-none">
            {props?.Request_A_Viewing_CTA &&
                <Row className="mt-0 mb-0 ">
                    <Col lg={12} className="footer-btn-col">
                        {props?.Book_A_Viewing_CTA ?
                            <a href="javascript:void(0)" onClick={handleClick} className="btn btn-dark">Request a viewing</a>
                        :
                            <Link className="btn btn-dark" to={BAC_PAGE_URL.alias}>Book a Consultation</Link>
                        }
                        {props.Homepage != true && <a href={"tel:01844399292"} class="btn btn-outline">Call 01844 399292</a>}
                    </Col>
                </Row>
            }
        </Container>
    );
}
export default RequestAViwing;