import React, { useEffect } from "react"
import { Helmet } from "react-helmet";
import Layout from "@Components/Layout/layout"
import SEO from "@Components/SEO/seo"
import { graphql } from 'gatsby'
import HomeModules from '@Components/home-modules';
import RequestAViwing from "../Components/RequestAViewing/RequestAViewing";

const HomeTemplate = (props) => {

  const Modules = props.data.glstrapi?.page?.Modules
  const Page = props.data.glstrapi?.page
  // const contact = props.data.glstrapi?.contact


  return (
    <>
    <Layout popularSearch={Page?.Select_Popular_Search ? Page?.Select_Popular_Search : "Common" } menutype={Page?.Select_Header_Style === "Transparent"?"menu-transparent":""} header={Page?.Pagename === "News"? true : Page?.Pagename === "Contact" ? true : Page?.Pagename === "Meet Our Team" ? true : Page?.Pagename === "Our Areas" ? true : false} Modules={Modules ? Modules : ""} 
    // contact={contact}
    >
      <SEO title={Page ? Page?.Meta_Title : ''} description={Page ? Page.Meta_Description : ''} />
      <Helmet
        bodyAttributes={{
          class: `templates-default-template alias-${Page ? Page?.Alias : ''} homepage ${Page ? Page?.Custom_CSS_Class : ''}`
        }}
      />
      {Page ?
      <HomeModules
        Modules={Modules}
        Page={Page}
        // contact={contact}
      />
      : null}
    </Layout>
    <RequestAViwing Homepage={Page?.isHomePage} Request_A_Viewing_CTA={Page?.Request_A_Viewing_CTA} />
    </>
  )  
}

export default HomeTemplate


export const pageQuery = graphql`
  query HomeQuery($id: ID!) {
    glstrapi {
      page(id: $id, publicationState: LIVE) {
        Alias
        isHomePage
        Custom_CSS_Class
        Layout
        Select_Header_Style
        Meta_Description
        Pagename
        Publish
        id
        imagetransforms
        ggfx_results {
          id
          content_type
          transforms
          src_import_url
          src_cftle
          field
        }
      Banner_Content
      Select_Popular_Search
      Request_A_Viewing_CTA
      Banner_Title
      Show_Review
      CTA_Links {
        CTA_Btn_Label1
        CTA_Btn_Label2
        CTA_Url1 {
          URL
          Alias
        }
        CTA_Url2 {
          URL
          Alias
        }
      }
      Banner_Image {
        alternativeText
        url
      }
        Modules {
          ... on GLSTRAPI_ComponentModuleModules {
            id
            Select_Module
            Title
            Content
          }
          ... on GLSTRAPI_ComponentModuleStaticPageContent {
            __typename
            id
            Content
          }
          ... on GLSTRAPI_ComponentModuleAreaguidesBlocks {
            __typename
            id
            Areaguide_Block1_Content
            Areaguide_Block1_Title
            Areaguide_Block2_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: WEBP
                    width: 1500
                    quality: 75
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )
                }
              }
            }
            Areaguide_Block2_Content
            Areaguide_Block2_Title
            Areaguide_Block1_Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: WEBP
                    width: 1500
                    quality: 75
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentModuleChooseOffices {
            id
            Offices {
              Office_Name
              Office_Address
              Lettings_Phone
              Sales_Phone
              Office_Email
              URL
              Choose_Person {
                id
                imagetransforms
                ggfx_results {
                  id
                  content_type
                  transforms
                  src_import_url
                  src_cftle
                  field
                }
                URL
                Name
                Email
                Designation
                Book_A_Video
                Image {
                  alternativeText
                  url
                }
                
              }
            }
          }
          ... on GLSTRAPI_ComponentModuleSectionBlock {
            id
            Add_Section {
              Content
              Title
              Video_Url
              Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 1500
                      quality: 75
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )
                  }
                }
                
              }
              Cta_Label
              Cta_Url {
                Alias
              }
            }
          }
          ... on GLSTRAPI_ComponentModuleBookConsultation {
            id
            Book_Title
            Content
            Cta_Label_1
            Cta_Label_2
            Cta_Url_2 {
              Alias
            }
            Cta_Url_1 {
              Alias
            }
          }
          ... on GLSTRAPI_ComponentModuleCollections {
            id
            Select_Collection
            Content
            Sub_Title
            Title
            Right_Image {
              url
              alternativeText
            }
          }
          ... on GLSTRAPI_ComponentModuleAbout {
            id
            Video_Url
            Title
            Title1
            Title2
            Person_Image {
              alternativeText
              url
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: WEBP
                    width: 1500
                    quality: 75
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )
                }
              }
            }
            Add_Cta {
              CTA_label
              CTA_Url {
                Alias
              }
              Content
              Title
              Icon {
                alternativeText
                url
              }
            }
            Cta {
              CTA_1_Label
              CTA_1_Url {
                Alias
              }
            }
          }
          ... on GLSTRAPI_ComponentModuleContentBlock {
            __typename
            id
            Content
            Video_Url
            Image {
              url
              alternativeText
              url_sharp {
                childImageSharp {
                  gatsbyImageData(formats: WEBP
                    width: 1500
                    quality: 75
                    layout: FIXED
                    transformOptions: {cropFocus: CENTER, fit: COVER}
                  )
                }
              }
            }
          }
          ... on GLSTRAPI_ComponentModuleStepsBlock {
            __typename
            id
            Title
            Content
            Add_Step {
              Title
              Cta_Label
              Content
              Image {
                url
                alternativeText
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 1500
                      quality: 75
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )
                  }
                }
              }
              Cta_Url {
                URL
              }
            }
          }
          ... on GLSTRAPI_ComponentModuleServicesCta {
            id
            __typename
            Title
            Sub_Title
            Content
            Add_Cta_Tail {
              CTA_1_label
              CTA_1_Url {
                URL
                Alias
              }
              Image {
                alternativeText
                url
                url_sharp {
                  childImageSharp {
                    gatsbyImageData(formats: WEBP
                      width: 1500
                      quality: 75
                      layout: FIXED
                      transformOptions: {cropFocus: CENTER, fit: COVER}
                    )
                  }
                }
              }
              CTA_1_Url {
                Alias
              }
              Add_Sub_Cta {
                CTA_label
                CTA_Url {
                  URL
                  Alias
                }
              }
            }
          }
        }
        Meta_Title

      }

     
    }
  }
`
