/**
 * Layout component that queries for datas
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React,{useEffect,useState} from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import "./layout.css"
import Header from "@Components/Header/Header";
import Footer from "@Components/Footer/Footer";
const Layout = ({ children, header, isBlack, popularSearch, menutype }) => {

  
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 60)
    })
  }, [])



  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)



  return (
    <>
      {isBlack ?
      <div className="header-logo-black"><Header/></div> : 
      menutype === "menu-transparent"?
      <div className={scroll ? "header-logo-black menu-transparent menu-scroll" : "header-logo-black menu-transparent"}><Header  siteTitle={data.site.siteMetadata?.title || `Title`} /></div>
       :
        <Header menutype={menutype} relative={header} siteTitle={data.site.siteMetadata?.title || `Title`} /> }
        <main>{children}</main>
        <Footer popularSearch={popularSearch} />
      
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.node.isRequired,
  isBlack : PropTypes.node.isRequired,
  popularSearch : PropTypes.node.isRequired,
}

export default Layout
