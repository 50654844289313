import React, { useEffect, useState } from "react";
import { Link } from "@StarberryUtils";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col } from "react-bootstrap";
import "./HomeBanner.scss";
import GoogleBannerImgD from "../../../images/home/google-reviews-dark-d.png"
import GoogleBannerImgT from "../../../images/home/google-reviews-dark-t.png"
import GoogleBannerImgM from "../../../images/home/google-reviews-dark-m.png"
import ReactMarkdown from "react-markdown"
import axios from "axios"
// import wordsToNumbers from 'words-to-numbers';
// import Homebannersvg from "./Homebannersvg";
import { Reviews_Page_Url } from "@Components/common/site/constants";
import { inViewOptions } from '../../../Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import loadable from '@loadable/component'
import { ElfsightWidget } from 'react-elfsight-widget';
const Homebannersvg = loadable(() => import("./Homebannersvg"));

const containerVariants = {
  hidden: {
    opacity: 0
  },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.4,
      ease: [0.33, 1, 0.68, 1],
      duration: 0.4
    }
  }
}

const titleVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 0.4,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const contentVariants = {
  hidden: {
    opacity: 0,
    y: 40
  },
  visible: {
    opacity: 1,
    y: 0,
    delay: 1,
    transition: {
      ease: [0.33, 1, 0.68, 1],
      duration: 1
    }
  }
}

const HomeBanner = (props) => {
  const [testimonials, setTestimonials] = useState([])
  const [isClient, setClient] = useState(false);
  const getitems = async url => {
    try {
       const { data } = await axios.get(url, {
          headers: {
            Authorization: `Bearer ${process.env.GATSBY_STRAPI_FORM_TOKEN}`,
          }
      })// could be from env files
      setTestimonials(data)
    } catch (error) {
      // cache it if fail/error;
      console.error(error)
    }
  }
  useEffect(() => {
    let url = `${process.env.GATSBY_STRAPI_SRC}/google-review-reviews`
    // getitems(url);
    setClient(true);
  }, [])
  var testimonial_count = testimonials ? testimonials.length : 0;
  var testimonial_count =330;
  // var rating_count = 332;
  // testimonials && testimonials.length > 0 && testimonials.map((item, ind) => {
  //   rating_count += parseInt(wordsToNumbers(item.starRating));
  // })
  var rating_avg = 4.5;

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => {
        return (
          <motion.section
            ref={ref}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            variants={containerVariants}
          >
            <React.Fragment>
              <section className="home-banner">
                <Container>
                  <Row>
                    <Col md={12}>
                      <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
                        {/* <ScrollAnimation animateOnce={true} animateIn='fadeIn'> */}
                        {/* <h1>{props?.data?.Banner_Title}</h1> */}
                        {/* <h1>Your <span class="stroke-text">ethically driven</span> property partner.</h1> */}
                        <motion.div variants={titleVariants}>
                          <h1><Homebannersvg /></h1>
                        </motion.div>

                        <motion.div variants={contentVariants}>
                          {isClient && props.data?.Banner_Content ?
                            <p className="banner-p-content">
                              <ReactMarkdown source={props.data?.Banner_Content} allowDangerousHtml />
                            </p>
                            : ''}
                          {props?.data?.Show_Review &&
                            <div className="elfsightWidget-class">
                              <ElfsightWidget widgetId="e133d153-5794-41f7-89b1-9cad5a3c7b7f" lazy />
                            </div>
                          }
                          {/* </ScrollAnimation> */}
                        </motion.div>
                      </ScrollAnimation>
                    </Col>
                  </Row>
                </Container>
              </section>
            </React.Fragment>
          </motion.section>
        )
      }}
    </InView>
  )
}

export default HomeBanner;
