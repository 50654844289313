import React, { useRef,useEffect,useState } from 'react';
/**
 * Components
 */
 import HomeBanner from "@Components/Home/HomeBanner/HomeBanner";
 import loadable from '@loadable/component';
//  import HomeIntro from "@Components/Home/HomeIntro/HomeIntro";
//  import HomeGetStart from "@Components/Home/HomeGetStart/HomeGetStart";
//  import HowCanWeHelp from "@Components/Home/HowCanWeHelp/HowCanWeHelp";
//  import HomeTeam from "@Components/Home/HomeTeam/HomeTeam";
// import ReviewsListModule from "@Components/reviews-listing-module";
// import HomeBookConsultation from "@Components/Home/HomeBookConsultation/HomeBookConsultation";
import { isMacOs, isSafari } from 'react-device-detect';
const HomeIntro = loadable(() => import('@Components/Home/HomeIntro/HomeIntro'));
const HomeGetStart = loadable(() => import('@Components/Home/HomeGetStart/HomeGetStart'));
const HowCanWeHelp = loadable(() => import('@Components/Home/HowCanWeHelp/HowCanWeHelp'));
const HomeTeam = loadable(() => import('@Components/Home/HomeTeam/HomeTeam'));
const ReviewsListModule = loadable(() => import('@Components/reviews-listing-module'));
const HomeBookConsultation = loadable(() => import('@Components/Home/HomeBookConsultation/HomeBookConsultation'));

const HomeModules = (props) => {
    // const AreaBlocks = props.Modules?props.Modules[1]:'';
    const myRef = useRef(null)
    const executeScroll = () => {
        myRef.current.scrollIntoView({ behavior: 'smooth'})
    }
    const [renderComponent, setRenderComponent] = useState(false);
    useEffect(() => {
      window.addEventListener("mousemove", () => {
        if (renderComponent === false) {
          setRenderComponent(true)
        }
        })
        window.addEventListener("touchmove", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
        window.addEventListener("keypress", () => {
            if (renderComponent === false) {
            setRenderComponent(true)
            }
        })
    }, [])
    return (

        <div className={`${(isSafari || isMacOs) ? "mac-saf" : ""}`}> 
            <HomeBanner data={props.Page} executeScroll={executeScroll}/>
            {props.Modules && props.Modules?.length > 0 ? props.Modules.map((Module, i) => {
                return (
                    <div key={i}>
                       {Module.Person_Image?.url &&
                        <HomeIntro
                        Video_Url={Module.Video_Url}
                        Title={Module.Title}
                        Title1={Module.Title1}
                        Title2={Module.Title2}
                        Person_Image={Module.Person_Image}
                        Add_Cta={Module.Add_Cta}
                        Cta={Module.Cta}
                        />
                        }
                         {Module.Select_Collection === "GetStart" && renderComponent &&
                            <HomeGetStart  
                            nobanner={props.Page.Alias === 'careers' ? true : false}
                            Content={Module.Content}
                                Sub_Title={Module.Sub_Title}
                                Title={Module.Title}
                              />
                        }

                        {Module.__typename === "GLSTRAPI_ComponentModuleServicesCta" && renderComponent &&
                        <>
                        {Module.Add_Cta_Tail && Module.Add_Cta_Tail[0].Add_Sub_Cta.length <= 0 && renderComponent &&
                        <HowCanWeHelp investments={props.Page.Alias === 'investments' ? true : false} isMainPage={props.Page.Pagename === "Home" ? true : false} Title={Module.Title} SubTitle={Module.Sub_Title} Content={Module.Content}
                        CTAS = {Module.Add_Cta_Tail} />                        
                        }
                        </>                        
                        }
                         {Module.Offices?.length > 0 && renderComponent &&
                            <HomeTeam Offices={Module.Offices} />
                        }

                        {Module.Select_Collection === "Customers_Google_Reviews" && renderComponent &&
                            <ReviewsListModule 
                                Select_Collection={Module.Select_Collection}
                                Content={Module.Content}
                                Sub_Title={Module.Sub_Title}
                                Title={Module.Title}
                            />
                        }   
                          {Module.Book_Title && renderComponent &&
                            <HomeBookConsultation
                            Title={Module.Book_Title}
                            Content={Module.Content}
                            Cta_Label_1={Module.Cta_Label_1}
                            Cta_Label_2={Module.Cta_Label_2}
                            Cta_Url_2={Module.Cta_Url_2}
                            Cta_Url_1={Module.Cta_Url_1}
                            />
                        }                                       

                    </div>
                )

            }) : ''
            }            
        </div>

    )
}
export default HomeModules
